<template>
    <Card>
        <div class="flex is-flex-wrap-wrap is-justify-content-space-between is-align-items-center mb-6">
            <h2 class="is-size-5 has-text-weight-bold mb-2">
                {{ $t("admin.dashboard.mainCards.newLaunches.title") }}
            </h2>

            <div class="flex">
                <CustomPeriod
                    v-model="datepicker"
                    :periods="['yesterday', 'today', 'week', 'month']">
                </CustomPeriod>
            </div>
        </div>

        <TablePlaceholder :data="data">
            <template #default="{ items, count }">
                <LTable
                    :data="items"
                    :loading="isLoading"
                    :count="count"
                    hide-pagination>
                    <b-table-column
                        v-slot="{ row }"
                        :label="$t(`common.entity.filters.webmaster`)">
                        <WebmasterOutput :webmaster="user(row)"></WebmasterOutput>
                    </b-table-column>

                    <b-table-column
                        v-slot="{ row }"
                        :label="$t(`common.entity.filters.offer`)">
                        <CustomOfferOutput :offer="offer(row)"></CustomOfferOutput>
                    </b-table-column>

                    <template #footer>
                        <InfiniteLoading
                            v-if="hasItems && isInfiniteLoading_"
                            @infinite="infiniteHandler">
                            <div slot="spinner"></div>
                        </InfiniteLoading>
                    </template>
                </LTable>
            </template>
        </TablePlaceholder>
    </Card>
</template>

<script>
  import CustomOfferOutput from "@/components/Common/Output/CustomOfferOutput";
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import WebmasterOutput from "@/components/Common/Output/WebmasterOutput";
  import CustomPeriod from "@/components/Common/PeriodButtons";
  import InfiniteLoading from "vue-infinite-loading";
  import LTable from "@/components/Common/LTable";
  import Card from "@/components/Common/Card";
  import { mapActions, mapState } from "vuex";
  import {
    GET_DASHBOARD_LAUNCH_ACTIVITY_WIDGET
  } from "@core/store/action-constants";
  import { formatEmptyString, formatPercent, toFixed } from "@/filters";
  import { mapFields } from "@vasiliyrusin/vue-mapfields";
  import { UPDATE_FILTERS, UPDATE_PAGINATION } from "@core/store/mutation-constants";

  export default {
    name: "DashboardNewLaunchesWidget",
    components: {
      CustomOfferOutput,
      TablePlaceholder,
      InfiniteLoading,
      WebmasterOutput,
      CustomPeriod,
      LTable,
      Card
    },

    computed: {
      ...mapState("admin/dashboard/dashboardStatisticWidget/dashboardLaunchActivityWidget", {
        hasItems: ({ leadLaunchActivityWidget }) => !!leadLaunchActivityWidget?.items.length,
        data: ({ leadLaunchActivityWidget }) => leadLaunchActivityWidget,
        pagination: ({ pagination }) => pagination
      }),

      ...mapFields("admin/dashboard/dashboardStatisticWidget/dashboardLaunchActivityWidget", {
        fields: ["datepicker"],
        base: "filters",
        action: UPDATE_FILTERS
      }),

      isInfiniteLoading_ () {
        const { perPage, page } = this.pagination;
        return perPage * page < this.data?.count;
      },

      isLoading () {
        return this.$wait(`admin/dashboard/dashboardStatisticWidget/dashboardLaunchActivityWidget/${ GET_DASHBOARD_LAUNCH_ACTIVITY_WIDGET }`);
      }
    },

    methods: {
      formatEmptyString,
      formatPercent,
      toFixed,

      ...mapActions("admin/dashboard/dashboardStatisticWidget/dashboardLaunchActivityWidget", [
        GET_DASHBOARD_LAUNCH_ACTIVITY_WIDGET,
        UPDATE_PAGINATION
      ]),

      async infiniteHandler ($state) {
        const pagination = {
          page: this.pagination.page + 1,
          perPage: this.pagination.perPage
        };
        await this.UPDATE_PAGINATION(pagination);
        await this.GET_DASHBOARD_LAUNCH_ACTIVITY_WIDGET();

        $state.loaded();
      },

      offer ({ offer = {} }) {
        return { name: offer.detail?.name, intId: offer.intId, id: offer.id, slug: offer.slug };
      },

      user ({ webmaster = {} }) {
        return { login: webmaster.login, intId: webmaster.intId, id: webmaster.id, subType: webmaster.subType };
      }
    }
  };
</script>

<!--suppress SassScssResolvedByNameOnly -->
<style lang="scss" scoped>
  @import "~@/scss/dashboard";
  @import "~@/scss/dashboardTable";

  ::v-deep {
    .b-table {
      position: relative;

      .table-wrapper {
        position: static;

        padding-bottom: 1px;
        max-height: 300px;
        overflow: auto !important;

        %absolutContent {
          content: "";
          position: absolute;
          top: 0;
          z-index: 1;

          width: 1px;
          height: 10px;
          background-color: white;
        }

        tfoot {
          position: static;
        }

        thead {
          tr {
            th {
              top: 0;

              &:first-child::before {
                @extend %absolutContent;
                left: 0;
              }

              &:last-child::before {
                @extend %absolutContent;
                right: 0;
              }
            }
          }
        }
      }
    }

  }
</style>
