<template>
    <Component
        :is="componentInstance"
        v-bind="$attrs"
        :value="value"
        :get-data-vuex="getUsers"
        :placeholder="placeholder"
        :multiple="multiple"
        class="white"
        field="login"
        :required="required"
        @[event]="$emit(event, $event)">
        <template #text="{ option }">
            <div class="is-align-items-center">
                <b-icon
                    v-if="option.activity.isBlocked"
                    class="has-text-light mr-2"
                    icon="ban"
                    size="is-small">
                </b-icon>
                <UserOutput :user="option"></UserOutput>
            </div>
        </template>
        <template #selected-option="{ option }">
            <div class="is-align-items-center">
                <b-icon
                    v-if="option.activity.isBlocked"
                    class="has-text-light mr-2"
                    icon="ban"
                    size="is-small">
                </b-icon>
                <UserOutput :user="option"></UserOutput>
            </div>
        </template>
    </Component>
</template>

<script>
  import { UsersSelect } from "@core/mixins/select/usersSelect";
  import UserOutput from "@/components/Common/Output/UserOutput.vue";

  export default {
    name: "ManagersSelect",
    mixins: [UsersSelect],

    components: { UserOutput },

    props: {
      routeParam: {
        type: String,
        default: "managerId"
      },

      namespaceModule: {
        type: String,
        default: "managersFilters"
      },

      required: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      async getUsers (label = "", isLoading = false, selectAll = false) {
        await this.getUserFilters(this.namespaceModule, this.list, this.$route.query[this.routeParam] ?? this.value,
                                  this.routeParam, label, isLoading, selectAll, this.filterOptions, this.sortOptions);
        return this.list;
      }
    }
  };
</script>

<style scoped>

</style>