<template>
    <div>
        <slot v-if="defaultContent"></slot>
        <ModalBox
            :is-active="isActive"
            :full-screen="fullScreen || isFullScreenMobile"
            width="100%"
            :class="availableDeviseOrientation ? `is-${ availableDeviseOrientation }` : ''"
            @created="createCalculateDeviceOrientation"
            @beforeDestroy="destroyCalculateDeviceOrientation"
            @close="close">
            <slot></slot>
        </ModalBox>
    </div>
</template>

<script>
  import ModalBox from "@/components/Common/Modals/ModalBox.vue";
  import { SET_EMPTY } from "@core/store/mutation-constants";
  import { mapMutations, mapState } from "vuex";
  import { UPDATE_DEVISE_ORIENTATION } from "@core/store/mutation-constants";

  export default {
    name: "ExpandFullscreen",
    components: { ModalBox },

    props: {
      availableOrientations: {
        type: Array,
        default: () => ["landscape-primary", "landscape-secondary", "portrait-primary", "portrait-secondary"],
        validator: (listOrientations) => listOrientations.length >= 1 && listOrientations.length <= 4
      },

      startOrientation: {
        type: String,
        default: "auto",
        validator: (orientation) =>
          ["landscape-primary", "landscape-secondary", "portrait-primary", "portrait-secondary", "auto"]
            .includes(orientation)
      },

      fixedOrientation: {
        type: Boolean,
        default: false
      },

      fullScreen: {
        type: Boolean,
        default: false
      },

      fullScreenMobile: {
        type: Boolean,
        default: true
      },

      defaultContent: {
        type: Boolean,
        default: true
      },

      value: {
        type: Boolean,
        default: null
      }
    },

    data () {
      return {
        isFullScreenMobile: false
      };
    },

    computed: {
      ...mapState("expandFullscreen", {
        isActiveModal: ({ isActiveModal }) => isActiveModal,
        deviseOrientation: ({ deviseOrientation }) => deviseOrientation
      }),

      isActive () {
        return this.value ?? this.isActiveModal;
      },

      availableDeviseOrientation () {
        if (this.availableOrientations.includes(this.deviseOrientation)) {
          const isOrientationPortraitSecondary = this.deviseOrientation === "portrait-secondary";

          return isOrientationPortraitSecondary ? "landscape-secondary" : this.deviseOrientation;
        } else {
          const isWrongStartOrientation = this.startOrientation === "auto" || !this.availableOrientations.includes(this.startOrientation);

          return isWrongStartOrientation ? this.availableOrientations[0] : this.startOrientation;
        }
      }
    },

    methods: {
      ...mapMutations("expandFullscreen", {
        SET_EMPTY,
        UPDATE_DEVISE_ORIENTATION
      }),

      createCalculateDeviceOrientation () {
        const MAX_WIDTH_DEVISE = "only screen and (max-width: 825px)";
        // Может ли разворачиваться на полный экран на мобильных девайсах
        this.isFullScreenMobile = this.fullScreenMobile && window.matchMedia(MAX_WIDTH_DEVISE).matches;

        if (window.matchMedia(MAX_WIDTH_DEVISE).matches) {
          if (!this.fixedOrientation) {
            this.calculateDeviceOrientation();
          }

          this.assignStartOrientation();
        }
      },

      calculateDeviceOrientation () {
        window.screen.orientation.addEventListener("change", this.UPDATE_DEVISE_ORIENTATION);
      },

      assignStartOrientation () {
        if (this.availableOrientations.includes(this.startOrientation)) {
          this.UPDATE_DEVISE_ORIENTATION(this.startOrientation);
        } else {
          this.UPDATE_DEVISE_ORIENTATION(this.deviseOrientation);
        }
      },

      destroyCalculateDeviceOrientation () {
        window.screen.orientation.removeEventListener("change", this.UPDATE_DEVISE_ORIENTATION);
      },

      close () {
        this.SET_EMPTY();
        this.$emit("close", false);
      }
    },

    beforeDestroy () {
      this.destroyCalculateDeviceOrientation();
    }
  };
</script>

<style lang="scss" scoped>
    $portrait-orientation: portrait;
    $landscape-orientation: landscape;

    ::v-deep {
        .modal-card {
            &:not(.is-full) {
                width: 90vw;
                max-width: 90vw;
            }

            &.is-full {
                .modal-card {
                    &-head, &-foot {
                        padding: 0;
                        height: 20px;
                        background-color: whitesmoke;
                    }

                    &-body, {
                        background-color: whitesmoke;
                    }
                }
            }
        }

        .modal-close {
            background-color: rgba(10, 10, 10, 0.2);

            &:hover {
                background-color: rgba(10, 10, 10, 0.3);
            }
        }
    }

    .modal {
        @media only screen and (max-width: 825px) {
            ::v-deep {
                .chart {
                    .card-content {
                        padding: 0;
                        width: 100%;
                    }
                }
            }

            ::v-deep {
                .modal-card {
                    &-body {
                        .chart-filters {
                            display: none;
                        }
                    }
                }

                .modal-close {
                    display: none;
                }
            }

            &.is-portrait-primary {
                ::v-deep {
                    .chart {
                        height: 93vh;
                        min-height: 93vh;

                        .card-content {
                            height: 93vh;
                        }
                    }
                }

                @media (orientation: $landscape-orientation) {
                    transform: rotate(90deg) translate(0%, -100%);
                    width: 100vh;
                    height: 100vw;
                    transform-origin: 0 0;

                    ::v-deep {
                        .animation-content, .modal-card {
                            height: 100vw;
                            max-height: 100vw;
                        }

                        .chart {
                            height: 93vw;
                            min-height: 93vw;

                            .card-content {
                                height: 93vw;
                            }
                        }
                    }
                }
            }

            &.is-portrait-secondary {
                @media (orientation: $portrait-orientation) {
                    transform: rotate(180deg);

                    ::v-deep {
                        .chart {
                            height: 93vh;
                            min-height: 93vh;

                            .card-content {
                                height: 93vh;
                            }
                        }
                    }
                }

                @media (orientation: $landscape-orientation) {
                    transform: rotate(270deg) translate(-100%, 0%);
                    width: 100vh;
                    height: 100vw;
                    transform-origin: 0 0;

                    ::v-deep {
                        .animation-content, .modal-card {
                            height: 100vw;
                            max-height: 100vw;
                        }

                        .chart {
                            height: 93vw;
                            min-height: 93vw;

                            .card-content {
                                height: 93vw;
                            }
                        }
                    }
                }
            }

            &.is-landscape-primary {
                ::v-deep {
                    .chart {
                        height: 90vh;
                        min-height: 90vh;

                        .card-content {
                            height: 90vh;
                        }
                    }
                }

                @media (orientation: $portrait-orientation) {
                    transform: rotate(90deg) translate(0%, -100%);
                    width: 100vh;
                    height: 100vw;
                    transform-origin: 0 0;

                    ::v-deep {
                        .chart {
                            height: 90vw;
                            min-height: 90vw;

                            .card-content {
                                height: 90vw;
                            }
                        }
                    }
                }
            }

            &.is-landscape-secondary {
                @media (orientation: $portrait-orientation) {
                    transform: rotate(270deg) translate(-100%, 0%);
                    width: 100vh;
                    height: 100vw;
                    transform-origin: 0 0;

                    ::v-deep {
                        .chart {
                            height: 90vw;
                            min-height: 90vw;

                            .card-content {
                                height: 90vw;
                            }
                        }
                    }
                }

                @media (orientation: $landscape-orientation) {
                    transform: rotate(180deg);
                    width: 100vw;
                    height: 100vh;
                    transform-origin: center;

                    ::v-deep {
                        .chart {
                            height: 90vh;
                            min-height: 90vh;

                            .card-content {
                                height: 90vh;
                            }
                        }
                    }
                }
            }
        }
    }
</style>